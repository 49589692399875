<template>
  <div id="app" class="w-full font-heebo font-normal">    
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
</style>
