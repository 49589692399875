import { render, staticRenderFns } from "./Bigheader.vue?vue&type=template&id=6774899c&scoped=true&"
import script from "./Bigheader.vue?vue&type=script&lang=js&"
export * from "./Bigheader.vue?vue&type=script&lang=js&"
import style0 from "./Bigheader.vue?vue&type=style&index=0&id=6774899c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6774899c",
  null
  
)

export default component.exports